<template>
     <div>
      <b-row>
          <b-col cols="12">
          <b-card class="my-card m-0">
              <b-table
                  striped
                  small
                  id="myTabless"
                  bordered
                  selectable
                  selected-variant="primary"
                  select-mode="single"
                  responsive="sm"
                  :items="tariffSettingsWork"
                  :fields="fields"
                  head-row-variant="secondary"
                  @row-contextmenu="handleContextMenuEvent"
                  @row-clicked="clicked"
                  >
                  <template #cell(checkbox)="row">
                          <b-form-checkbox
                          v-model="row.detailsShowing"
                          plain
                          class="vs-checkbox-con"
                          @change="row.toggleDetails"
                          >
                          </b-form-checkbox>
                      </template> 
                  <template #cell(blocked)="data">
                      <span>
                          {{data.item.blocked === 1 ? 'Да' : 'Не'}}
                      </span>
                  </template>
                  <template #cell(weekdays)="data">
                      <span v-for="(weekdays) in data.item.weekdays" :key="weekdays">
                          ({{ weekdays }})
                      </span>
                  </template>
                  <template #cell(price)="data">
                      <span class="reference-field" @click.prevent="getPrice(data)">
                          (***)
                      </span>
                  </template>
                  <template #cell(prices)="data">
                      <span v-for="prices in data.item.prices" :key="prices.id">
                         {{ prices.id }}
                      </span>
                  </template>
              </b-table>
              <vue-context ref="menu">
                  <li>
                      <b-link
                      class="d-flex align-items-center"
                      @click="autoUpdate"
                      >
                      <img :src="$store.state.updates.update ? iconsCheck : iconsSquare">
                      <span class="ml-75">Автообновление</span>
                      </b-link>
                  </li>
              </vue-context>
              </b-card> 
          </b-col>
      </b-row>
    </div>
</template>


<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js"
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
    components: {
        VueContext,
    }, 
    data() {
        return {
            tariffSettingsWork: [],
            fields: [
                {key: 'checkbox', label: '', thStyle: {width: '5px'}},
                {key: 'base_tariff_performer_name', label: 'Тип', sortable: true, thStyle: {width: '50px'}},
                {key: 'priority', label: 'Приоритет', sortable: true, thStyle: {width: '50px'}},
                {key: 'weekdays', label: 'Дни недели', sortable: true, thStyle: {width: '50px'}},
                {key: 'order_type_name', label: 'Тип тарифа заказа', sortable: true, thStyle: {width: '50px'}},
                {key: 'start_time', label: 'Время начала', sortable: true, thStyle: {width: '50px'}},
                {key: 'end_time', label: 'Время окончания', sortable: true, thStyle: {width: '50px'}},
                {key: 'price', label: 'Цена', sortable: true, thStyle: {width: '50px'}},
                {key: 'write_commission_assigning_performer', label: 'Порог цени', sortable: true, thStyle: {width: '50px'}},
                {key: 'price_limit', label: 'По цене', sortable: true, thStyle: {width: '50px'}},
                {key: '7', label: 'По расстояние', sortable: true, thStyle: {width: '50px'}},
                {key: '8', label: 'По времени', sortable: true, thStyle: {width: '50px'}},
                {key: 'blocked', label: 'Статус', sortable: true, thStyle: {width: '50px'}},
                {key: 'status_performer_name', label: 'Список', sortable: true, thStyle: {width: '50px'}},
                {key: 'base_tariff_performer_id', label: 'ID тарифа', sortable: true, thStyle: {width: '50px'}},
            ],
            iconsSquare: require('@/assets/images/icons/square.svg'),
            iconsCheck: require('@/assets/images/icons/checkSquareIcon.svg')
        }
    },
    mounted() {
        this.$store.commit('pageData/setdataCount', null)
        resizeable()
        this.$http.get(`tariff-performers/${this.$route.query.filter}/settings`)
                .then(res => {
                    this.tariffSettingsWork = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffSettingsWork.length)
                })
    },
    methods: {
        refresh(){
            this.$http.get(`tariff-performers/${this.$route.query.filter}/settings`)
                .then(res => {
                    this.tariffSettingsWork = res.data
                    this.$store.commit('pageData/setdataCount', this.tariffSettingsWork.length)
                })
        },
        handleContextMenuEvent(item, index, event){
            event.preventDefault();
            this.$refs.menu.open(event);
        },
        autoUpdate() {
            this.$store.state.updates.update = !this.$store.state.updates.update;
            this.timerAfto();
        },
        getPrice(data) {
            this.$store.commit('draggableTab/ADD_TAB',['Цена тарифа ' + data.item.id, `/tariff/price/work`, data.item.id])
            this.$router.push({name: 'tariff/price/work', query:{filter: data.item.id}})
        },
        timerAfto() {
            this.$store.state.updates.update ? (this.$store.state.updates.timer = setInterval(() => this.refresh(), 30000)) : clearInterval(this.$store.state.updates.timer);
        },
        clicked(item, index, event){
              this.$store.state.filterPlus.tableIndex = index
  
              const clickedElement = event.target;
              const clickedCell = clickedElement.closest('td');
  
              if (!clickedCell) {
                  return;
              }
  
              const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);
  
              if (columnIndex < 0 || columnIndex >= this.fields.length) {
                  return;
              }
  
              const clickedField = this.fields[columnIndex];
              this.$store.state.filterPlus.keyFilter = clickedField.key;
              this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
              cellSelect()
          },
    },
    watch: {
    '$route.query.filter': function(newPage, oldPage) {
            if (newPage !== oldPage) {
                console.log(newPage);
            this.$http
                    .get(`tariff-performers/${this.$route.query.filter}/settings`)
                    .then(res => {
                        this.tariffSettingsWork = res.data
                        this.$store.commit('pageData/setdataCount', this.tariffSettingsWork.length)
                    })
            }
        }
  }
}
</script>


<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-context.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
table{
    outline: none; 
    table-layout: fixed; 
    -webkit-user-select: none;
    overflow: hidden;
    position: relative;
}
tr, td, th{
    padding: 0;
}
td, th{ 
    width: 100px; 
    white-space: nowrap; 
    overflow: hidden;  
    text-align: left; 
    box-sizing: border-box; 
    min-width: 60px;
    }
</style>